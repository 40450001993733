<script>
  import { onMount } from "svelte";
  import { createClient } from "@supabase/supabase-js";

  const supabaseUrl = "https://viswgnjgfpzstdxekczs.supabase.co";
  const supabaseKey =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZpc3dnbmpnZnB6c3RkeGVrY3pzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzc2ODU0MjksImV4cCI6MTk5MzI2MTQyOX0.IzeCqcdBABmKqv7Yz8le1BHCdhEz_YhEtvQ0I9DpGgI";

  const supabase = createClient(supabaseUrl, supabaseKey);

  let email = "";
  let password = "";
  let error_msg = null;

  const login = async () => {
    const {
      data: { user },
      error,
    } = await supabase.auth.signInWithPassword({ email, password });
    // const { error } = await supabase.auth.signInWithPassword({
    //   email,
    //   password,
    // });

    if (error) {
      error_msg = "Invalid email or password";
      console.error(error_msg);
      console.log(error)
      return;
    }

    console.log(user)

    window.location.href = "/add-photos/";
  };

  onMount(async () => {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    const { user } = session;

    if (user) {
      window.location.href = "/add-photos/";
    }
  });
</script>

<div class="login"> 
  <strong>Login Required</strong>
  {#if error_msg}
    <p class="message">{error_msg}</p>
  {/if}
  <div>
    <label for="email-input">Email Address</label>
    <input id="email-input" type="email" bind:value={email} />
  </div>
  <div>
    <label for="password-input">Password</label>
    <input id="password-input" type="password" bind:value={password} />
  </div>
  <div>
    <button class="submit" on:click={login}>Log In</button>
  </div>
</div>
